import React from "./Spost.css"
import { useParams } from "react-router-dom"
import Records from "../../../records.json"
import Topbar from "../../Topbar/Topbar"
import MainSidebar from "../../MainSidebar/MainSidebar"
import Footer from "../../Footer/Footer"



function SinglePost() {

    const { postId } = useParams();
    // It will return the id number on which we click.
    console.log(postId);
    // console.log(Records[postId].category);
    // console.log(Records.json);
    // const image = "." + Records[postId].image;

    return (
        <div className="main">
            <div className='single'>
                <div className='bodyLeft'>
                    <div className="singlePostWrapper">
                        <div className="imgWrap">
                            <img className="singlePostImage" src="../images/img6.png" alt="Image not found" />
                        </div>
                        <h1 className="singlePostTitle">
                            <p style={{ color: "black" }}>{Records[2].category}</p>
                            <div className="editContainer">
                                <i className="singlePostIcon fa-regular fa-pen-to-square"></i>
                                <i className="singlePostIcon fa-solid fa-trash"></i>
                            </div>

                        </h1>
                        <h1 className="singlePostTitle">

                            <p>{Records[2].shortdes}</p>

                        </h1>
                        <div className="singlePostInfo">
                            <span className="singlePostAuthor">Author: <b>Sarfraz</b></span>
                            <span className="singlePostDate">Created On: <b>1h Ago</b></span>
                        </div>
                        <p className="des">{Records[2].description}</p>
                    </div>


                </div>
            <MainSidebar />
            </div>
        <Footer />

        </div>


    )
}

export default SinglePost
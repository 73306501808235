// We can keep or remove the 2nd line
import React from 'react'
import "./Header.css"

function Header() {
    return (
        <div className='header'>
            <div className="headerTitles">
                <span className="headerTitleSm">React & PHP</span>
                <span className="headerTitleLg"><i class="fa-solid fa-arrow-up-from-bracket"></i></span>
                <img className="headerImage " src="images/blog-image.jpg" alt="headerImage" />
            </div>
        </div>
    )
}

export default Header
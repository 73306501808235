import React from "./Blog.css"
import MainSidebar from "../../MainSidebar/MainSidebar"
import Records from "../../../records.json"
import { useState } from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom"
import Topbar from "../../Topbar/Topbar"
import Footer from "../../Footer/Footer"
function Blog() {
  const [productData, setProductData] = useState([]);


  useEffect(() => {
    getProductData();
  }, []);


  const getProductData = async () => {
    const reqData = await fetch("http://localhost/reactcrudphp2/api/product.php");
    const resData = await reqData.json();
    setProductData(resData);
    console.log("Fetching data....", resData);
  }

    return (
      <>
        <div className='Blog'>
          <div className='bodyLeft'>
            <div class="container">


              {
                productData.map(function (recdata) {
                  return (
                    <div className="card" key={recdata.id}>
                      <div class="card-header">
                        <NavLink to={`/SinglePost/${recdata.id}`}>
                          <img src={`http://localhost/reactcrudphp2/${recdata.file}`} alt="" className="img-fluid" />

                        </NavLink>
                      </div>
                      <div class="card-body">
                        <span class="tag tag-teal">{recdata.category}</span>
                        <h4>
                          {recdata.shortdes}
                        </h4>
                        <p>
                          {recdata.shortnote + "..."}
                          {/* concated "..." to add three dots after content */}
                        </p>
                        <div class="user">
                          <img src={`http://localhost/reactcrudphp2/${recdata.file}`} alt="" />
                          <div class="user-info">
                            <h5>{recdata.date}</h5>
                            <small>{recdata.time}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }



            </div>
            <div class="pagination-container">
              <ul class="pagination">
                <li>
                  <a href="#">Prev</a>
                </li>
                <li>
                  <a href="#">1</a>
                </li>
                <li class="active">
                  <a href="#">2</a>
                </li>
                <li>
                  <a href="#">3</a>
                </li>
                <li>
                  <a href="#">4</a>
                </li>
                <li>
                  <a href="#">5</a>
                </li>
                <li>
                  <a href="#">Next</a>
                </li>
              </ul>
            </div>
          </div>

          <MainSidebar />

        </div>
        <Footer />

      </>

    )
  }


export default Blog
import { Link } from "react-router-dom"
import "./Register.css"

function Register() {
    return (
        <div className="Register">
            <span className="RegisterTitle">Register</span>
            <form action="" className="RegisterForm">
                <label htmlFor="">Username</label>
                <input type="text" className="RegisterInput" placeholder="Enter Username" />
                <label htmlFor="">Email</label>
                <input type="text" className="RegisterInput" placeholder="Enter Email" />
                <label htmlFor="">Password</label>
                <input type="password" className="RegisterInput" placeholder="Enter Password" />
                <button className="RegisterButton">Register</button>
            </form>
            <button className="LoginButton"><Link className="removeUnderline" to="/login">Login</Link></button>
        </div>
    )

}

export default Register
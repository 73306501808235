import axios from "axios";
import React, { useState } from 'react'
import "./AddUser.css";
import Topbar from "../../Topbar/Topbar";
import {useNavigate } from "react-router-dom";


function AddBlog() {
    const navigate = useNavigate();
    const [category, setCategory] = useState('');
    const [shortdes, setShortdes] = useState('');
    const [description, setDescription] = useState('');
    const [shortnote, setShortnote] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [file, setFile] = useState('');
    const [message, setMessage] = useState('');

    const uploadProduct = async () => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('category', category);
        formData.append('shortdes', shortdes);
        formData.append('description', description);
        formData.append('shortnote', shortnote);
        formData.append('date', date);
        formData.append('time', time);

        const responce = await axios.post("http://localhost/reactcrudphp2/api/product.php", formData, {
            headers: { 'Content-Type': "multipart/form-data" },
        });


        if (responce.data.success) {
            setMessage(responce.data.success);
            setTimeout(() => {
                navigate('/Blog');
            }, 1000);

        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        await uploadProduct();

    }




    return (
        <>
            <div>
                <h1 className='text-center mt-4'>Add Blog</h1>
                <p className="text-warning">{message}</p>

                <form onSubmit={handleSubmit} className="formStyle mb-4" >
                    <div className="mb-3 row">
                        <label className="col-sm-4 col-md-2 col-form-label">Upload Image</label>
                        <div className="col-sm-8 col-md-10">
                        <input type="file" className="form-control" onChange={(e) => setFile(e.target.files[0])} />

                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="col-sm-4 col-md-2 col-form-label">category</label>
                        <div className="col-sm-8 col-md-10">
                            <input type="text" className="form-control" onChange={(e) => setShortdes(e.target.value)} />
                        </div>
                    </div>
                    {/* <div className="mb-3 row">
                    <label className="col-sm-4 col-md-2 col-form-label">Password</label>
                    <div className="col-sm-8 col-md-10">
                        <input type="password" name='password' className="form-control" />
                    </div>
                </div> */}

                    <div className="mb-3 row">
                        <label className="col-sm-4 col-md-2 col-form-label">Description</label>
                        <div className="col-sm-8 col-md-10">
                            <input type="text" className="form-control" onChange={(e) => setDescription(e.target.value)} />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="col-sm-4 col-md-2 col-form-label">Shortnote</label>
                        <div className="col-sm-8 col-md-10">
                            <input type="text" className="form-control" onChange={(e) => setShortnote(e.target.value)} />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label className="col-sm-4 col-md-2 col-form-label">Creation Date</label>
                        <div className="col-sm-8 col-md-10">
                            <input type="date" className="form-control" onChange={(e) => setDate(e.target.value)} />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="col-sm-4 col-md-2 col-form-label">Creation Time</label>
                        <div className="col-sm-8 col-md-10">
                            <input type="time" className="form-control" onChange={(e) => setTime(e.target.value)} />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="col-sm-4 col-md-2 col-form-label">shortdes</label>
                        <div className="col-sm-8 col-md-10">
                        <input type="text" className="form-control" onChange={(e) => setCategory(e.target.value)} />
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label className="col-sm-4 col-md-2 col-form-label"></label>
                        <div className="col-sm-8 col-md-10" name='submit'>
                            <button type='submit' className='btn btn-success'>Submit</button>
                        </div>
                    </div>
                </form>
            </div>

        </>
    )
}

export default AddBlog
import "./Login.css"
import { Link } from "react-router-dom"

function Login() {
    return (
        <div className="Login1">
            <span className="loginTitle">Login</span>
            <form action="" className="loginForm">
                <label htmlFor="">Email</label>
                <input type="text" className="loginInput" placeholder="Enter Email" />
                <label htmlFor="">Password</label>
                <input type="password" className="loginInput" placeholder="Enter Password" />
                <button className="loginButton">Login</button>
            </form>
            <button className="registerButton"><Link className="removeUnderline" to="/register">Register</Link></button>
        </div>
    )

}

export default Login
import "./MainSidebar.css"

function MainSidebar() {
    return (
        <div className='MainSidebar'>
            <div className="sidebarItem">
                <img className="sidebarImage" src="../images/img6.png" alt="" />
                <span className="sidebarTitle">About Us</span>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia, doloribus.</p>
            </div>
            <div className="sidebarItem">
                <span className="sidebarTitle">Categories</span>
                <ul className="sidebarList">
                    <li className="sidebarListItem">Happy</li>
                    <li className="sidebarListItem">Sad</li>
                    <li className="sidebarListItem">Cool</li>
                    <li className="sidebarListItem">Play</li>
                </ul>
            </div>
            <div className="sidebarItem">
                <span className="sidebarTitle">Follow Us</span>
                <div className="sidebarSocial">
                    <i className="sidbarIcons fa-brands fa-facebook-square"></i>
                    <i className="sidbarIcons fa-brands fa-square-twitter"></i>
                    <i className="sidbarIcons fa-brands fa-square-whatsapp"></i>
                </div>
            </div>

        </div>

    )
}

export default MainSidebar
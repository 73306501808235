import React from "./SinglePost.css"
import { useParams } from "react-router-dom"
import { useState, useEffect } from "react"
// import Records from "../../../records.json"
import Topbar from "../../Topbar/Topbar"
import Footer from "../../Footer/Footer"

function SinglePost() {
    const [formvalue, setFormvalue] = useState({ file: '', category: '', shortdes: '', description: '', shortnote: '', date: '', time: '' });

    const { id } = useParams();


    useEffect(() => {
        const userRowdata = async () => {
            const getUserdata = await fetch("http://localhost/reactcrudphp2/api/product.php/" + id);
            const resuserdata = await getUserdata.json();
            console.log(getUserdata);
            setFormvalue(resuserdata);
            console.log(formvalue);
        }
        userRowdata();
    }, []);

    return (
        <div className="main">
            <Topbar />
            <div className='single'>
                <div className='bodyLeft'>
                    <div className="singlePostWrapper">
                        <div className="imgWrap">
                            <img className="singlePostImage" src={`http://localhost/reactcrudphp2/${formvalue.file}`} alt="Image not found" />
                        </div>
                        <h1 className="singlePostTitle">
                            <p style={{ color: "black" }}>{formvalue.category}</p>
                            <div className="editContainer">
                                <i className="singlePostIcon fa-regular fa-pen-to-square"></i>
                                <i className="singlePostIcon fa-solid fa-trash"></i>
                            </div>
                        </h1>
                        <h1 className="singlePostTitle">
                            <p>{formvalue.shortdes}</p>
                        </h1>
                        <div className="singlePostInfo">
                            <span className="singlePostAuthor">Author: <b>Sarfraz</b></span>
                            <span className="singlePostDate">Created On: <b>{formvalue.time}</b></span>
                        </div>
                        <p className="des">{formvalue.description}</p>
                    </div>
                </div>

                <div className='sidebar'>
                    <div className="sidebarItem">
                        <img className="sidebarImage" src={`http://localhost/reactcrudphp2/${formvalue.file}`} alt="Image not found" />
                        <span className="sidebarTitle">About Us</span>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia, doloribus.</p>
                    </div>
                    <div className="sidebarItem">
                        <span className="sidebarTitle">{formvalue.category}</span>
                        <ul className="sidebarList">
                            <li className="sidebarListItem">Happy</li>
                            <li className="sidebarListItem">Sad</li>
                            <li className="sidebarListItem">Cool</li>
                            <li className="sidebarListItem">Play</li>
                        </ul>
                    </div>
                    <div className="sidebarItem">
                        <span className="sidebarTitle">Follow Us</span>
                        <div className="sidebarSocial">
                            <i className="sidbarIcons fa-brands fa-facebook-square"></i>
                            <i className="sidbarIcons fa-brands fa-square-twitter"></i>
                            <i className="sidbarIcons fa-brands fa-square-whatsapp"></i>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

        </div>


    )
}

export default SinglePost
import Home from "./Components/pages/home/Home";
import Blog from "./Components/pages/blog/Blog";
import Bpost from "./Components/Blogpost/Bpost";
import SinglePost from "./Components/pages/SinglePost/SinglePost";
import Write from "./Components/pages/write/Write";
import Settings from "./Components/pages/settings/Settings";
import Login from "./Components/pages/Login/Login";
import Register from "./Components/pages/Register/Register";
import Spost from "./Components/pages/Spost/Spost";
import AddBlog from "./Components/pages/AddBlog/AddBlog";
import Topbar from "./Components/Topbar/Topbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  const user = false;
  return (

    <div className="App">
      <BrowserRouter>
        <Topbar />
        <Routes>
          <Route path="/" element={<Home />}>
          </Route>
          <Route path="/Login" element={user ? <Bpost /> : <Login />}>
          </Route>
          <Route path="/register" element={user ? <Bpost /> : <Register />}>
          </Route>
          <Route path="/settings" element={user ? <Settings /> : <Register />}>
          </Route>
          <Route path="/Write" element={user ? <Write /> : <Register />}>
          </Route>
          <Route path="/SinglePost/:id" element={<SinglePost />}>
          </Route>
          <Route path="/SinglePost/" element={<Spost />}>
          </Route>
          <Route path="/blog" element={<Blog />}>
          </Route>
          <Route path="/AddBlog" element={<AddBlog />}>
          </Route>
        </Routes>

      </BrowserRouter>
    </div>


  );
}

export default App;
